.home{
    display: flex;

    .homeContainer{
        flex: 6;
        

        .widgets , .charts{
            display: flex;
            padding: 20px;
            gap: 20px;
        }

        .charts{
            padding: 5px 20px;
        }

        .listContainer{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201 , 201 , 0.47);
            padding: 10px;
            margin: 20px;
        }

        .listTitle{
            font-size: 16px;
            color: #42526e;
            font-weight: bold;
            margin-bottom: 25px;
        }
    }
}

.regulazation-form {
    .add-new-user-form{
        .formInput{
            .label-heading{
                font-weight: 600;
            }
        }
    }
    .btn{
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        background-color: #66686b;
        font-weight: 400;
        border: 1px solid #66686b;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }
    .statuscard{
        padding: 5px 10px;
        border-radius: 50px;
        margin-left: 10px;
    }
    .PENDING{
        border: 1px solid rgba(0, 0, 0, 0.548);
        color: rgba(0, 0, 0, 0.822);
        background-color: #fff;
    }
    .APPROVED{
        border: 1px solid green;
        color: green;
        background-color: #c2f1c2;
    }
    .CANCELLED{
        border: 1px solid #000;
        color: #000;
        background-color: #bfc2c7;
    }
    .REJECTED{
        border: 1px solid rgb(151, 15, 15);
        color: rgb(151, 15, 15);
        background-color: rgb(255, 184, 184);
    }

    .btn-submit{
        background-color: #0052cc;
        border: 1px solid #0052cc;
        margin-right: 10px;
    }
    .btn-approve{
        background-color: #0c7a27;
        border: 1px solid #0c7a27;
        margin-right: 10px;
    }

    .btn-cancel{
        background-color: rgb(151, 15, 15);
        border: 1px solid rgb(151, 15, 15);
    }

    .table-row-regular{
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #bebebe;
        padding: 15px 0px;
        .table-row-cell-regular{
            flex-basis: 50%;
        }
    }
}
.btn-full-width{
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    background-color: #0465f6;
    font-weight: 400;
    border: 1px solid #66686b;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    // margin-top: 50px;
}
.btn-full-width-inactive{
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    background-color: #66686b;
    font-weight: 400;
    border: 1px solid #66686b;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    // margin-top: 50px;
}