.jiraStatus {
    display: flex;
    width: 100%;

    .jiraStatusContainer {
        flex: 6;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 0px 10px 0px;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 10px 20px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
                text-transform: capitalize;
                margin-left: 10px;

                svg {
                    font-size: 16px;
                }
            }

            .btn-add-disabled {
                text-decoration: none;
                color: #ffffff !important;
                text-transform: capitalize;
                font-size: 16px;
                background-color: rgb(117, 117, 117) !important;
                font-weight: 400;
                border: 1px solid rgb(117, 117, 117) !important;
                padding: 10px 20px !important;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
            }
        }

        .view-details-row {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 20px;

            .detailItem {
                flex-basis: 32%;
                margin-bottom: 20px;

                .itemKey {
                    width: 30%;
                    display: inline-block;
                }

                .itemKey2 {
                    font-weight: 600;
                }
            }
        }

        .top {
            padding: 20px;
            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;


                .itemImg {
                    width: 100%;
                    height: unset;
                    border-radius: 8px;
                    object-fit: cover;
                }


            }
        }





    }
}

form {


    .add-new-user-form {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .formInput {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 49%;

            label {
                display: inline-block;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            input {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            select {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            .select {
                min-width: 100%;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            textarea {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }
        }

        .formInputDescription {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 100%;

            label {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            textarea {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }
        }


    }

    .btn-add-new {
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        background-color: #0052cc;
        font-weight: 400;
        border: 1px solid #0052cc;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
        text-transform: capitalize;
        margin-left: 10px;

        svg {
            font-size: 16px;
        }
    }

    .submit-btn-disabled {
        width: 100px;
        padding: 10px 20px;
        display: block;
        text-align: center;
        border: none;
        background-color: #706c6c !important;
        color: white !important;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
        text-decoration: none;
    }

}

.submit-modal {
    width: 100px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #0052cc !important;
    color: white !important;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}

.close-modal {
    width: 100px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #000 !important;
    color: white !important;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}