.editButton {
    color: #0052cc;
    font-size: 16px;
    display: block;

    svg {
        font-size: 16px;
        margin-right: 5px;
    }
}

.deleteButton {
    color: #cc0000 !important;
    font-size: 16px;
    display: block;

    svg {
        font-size: 16px;
        margin-right: 5px;
    }
}

.sycnButton {
    color: #00ccbb !important;
    font-size: 16px;
    display: block;

    svg {
        font-size: 16px;
        margin-right: 5px;
    }
}

.linkButton {
    color: #0030cc !important;
    font-size: 16px;
    display: block;

    svg {
        font-size: 16px;
        margin-right: 5px;
    }
}

.viewButton {
    color: #11cc00 !important;
    font-size: 16px;
    display: block;

    svg {
        font-size: 16px;
        margin-right: 5px;
    }
}

.workflow-tp-heading--bg {
    background: rgb(33, 150, 243);
    color: rgb(255, 255, 255);
    margin: 20px 20px 0px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    h3 {
        line-height: 2;
    }
}

.workflow-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .formInput {
        flex-basis: 49%;

        .label-heading {
            margin-top: 12px;
            display: block;
            margin-bottom: 12px;
        }

        .searchWrapper {
            min-width: 100%;
            border-radius: 4px !important;
            border: none;
            border: 1px solid #42526e !important;
            box-sizing: border-box;
            background-color: #fff;
            padding: 5px !important;
        }

    }

    .btn {
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        background-color: #66686b;
        font-weight: 400;
        border: 1px solid #66686b;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }

    .statuscard {
        padding: 5px 10px;
        border-radius: 50px;
        margin-left: 10px;
    }

    .PENDING {
        border: 1px solid rgba(0, 0, 0, 0.548);
        color: rgba(0, 0, 0, 0.822);
        background-color: #fff;
    }

    .ACTIVE {
        border: 1px solid green;
        color: green;
        background-color: #c2f1c2;
    }

    .INACTIVE {
        border: 1px solid rgb(151, 15, 15);
        color: rgb(151, 15, 15);
        background-color: rgb(255, 184, 184);
    }

    .btn-submit {
        background-color: #0052cc;
        border: 1px solid #0052cc;
        margin-right: 10px;
    }

    .btn-disabled {
        background-color: #646d7a;
        border: 1px solid #0052cc;
        margin-right: 10px;
    }

    .btn-approve {
        background-color: #0c7a27;
        border: 1px solid #0c7a27;
        margin-right: 10px;
    }

    .btn-cancel {
        background-color: rgb(151, 15, 15);
        border: 1px solid rgb(151, 15, 15);
    }

    .table-row-regular {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #bebebe;
        padding: 15px 0px;

        .table-row-cell-regular {
            flex-basis: 50%;
        }
    }
}