.new {
    display: flex;
    width: 100%;

    .newContainer {
        flex: 6;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 20px 10px 20px;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff !important;
                text-transform: capitalize;
                font-size: 16px;
                background-color: #0052cc !important;
                font-weight: 400;
                border: 1px solid #0052cc !important;
                // padding: 5px 20px !important;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
            }
            .btn-add-disabled{
                text-decoration: none;
                color: #ffffff !important;
                text-transform: capitalize;
                font-size: 16px;
                background-color: rgb(117, 117, 117) !important;
                font-weight: 400;
                border: 1px solid rgb(117, 117, 117) !important;
                padding: 10px 20px !important;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
            }
        }

        form {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 30%;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }


            }

            .btn-submit {
                width: 150px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }

        }

        .flex {
            display: flex;
            padding: 5px;
            align-items: space;
            justify-content: space-between;
            flex-wrap: wrap;
        }

    }
}

.assign-shift-modal {
    label {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #172b4d;
        margin-bottom: 6px;

        span {
            width: 70px;
            color: #172b4d;
            font-size: 16px;
            font-weight: 600;
        }
    }

    p {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #172b4d;
        margin-bottom: 6px;

        span {
            width: 70px;
            color: #172b4d;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .formInput {
        margin-bottom: 15px;
        box-sizing: border-box;
        flex-basis: 100%;
        margin-top: 20px;

        label {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #172b4d;
            margin-bottom: 6px;
        }

        

        select {
            min-width: 100%;
            padding: 10px 10px;
            border-radius: 4px;
            border: none;
            border: 1px solid #42526e;
            box-sizing: border-box;
            background-color: #fff;
            font-size: 16px;
        }

        
    }

    .btn-submit {
        min-width: 100px;
        padding: 5px 20px;
        display: block;
        text-align: center;
        border: none;
        background-color: #0052cc !important;
        color: white !important;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        text-decoration: none;
    }
}

