// css for form
.new {
    display: flex;
    width: 100%;

    .newContainer {
        flex: 4;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 10px 20px 10px 0px;
            width: 100%;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 10px 20px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
            }
        }

        form {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 30%;
                    color: #000;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }
            }

            .btn-submit {
                width: 150px;
                height: 40px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
        }

    }
}

//css for list
.listing {
    display: flex;
    width: 100%;

    .listingContainer {
        flex: 6;

        .loader {
            display: flex;
            margin-top: 26px;
            padding: 20px;
            flex-direction: row;
            justify-content: center;
        }

        .datatableTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            width: 97%;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 10px 20px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
            }
        }
    }

}

//for status 
.cellWithStatus {
    font-size: 16px;
    padding: 2px 10px;
    border-radius: 5px;

    &.true {
        background-color: #deebff;
        color: #0747a6;
    }

    &.pending {
        background-color: rgba(255, 217, 0, 0.05);
        color: goldenrod;
    }

    &.false {
        background-color: rgb(128 0 0 / 5%);
        color: crimson;
    }
}

//Error Message
.errorMessage {
    float: end;
    margin: 3px;
    font-size: 14px;

    p {
        color: #e33417;
        text-transform: capitalize;
    }
}