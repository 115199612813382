.homeContainer {
    .advancelisting-row {
        display: flex;
        flex-direction: row;
        border: 1px solid #ddd;
        margin: 20px;

        .advance-listing-colum-1 {
            flex-basis: 30%;
            border-right: 1px solid #ddd;

            .advance-listing-1-flex {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                background-color: rgba(0, 0, 0, 0.06);
            }

            ul {
                padding: 0px;
                margin: 0px;
                list-style-type: none;

                li {

                    a {
                        display: block;
                        text-decoration: none;
                        padding: 10px;
                        border-bottom: 1px solid #ddd;
                        transition: .3s;

                        .list-selector-row {
                            display: flex;
                            flex-direction: row;

                            .list-selector-colum-1 {
                                flex-basis: 20%;
                                text-align: center;

                                .profile-name {
                                    height: 40px;
                                    width: 40px;
                                    border-radius: 50%;
                                    background-color: rgb(4, 83, 43);
                                    color: #fff;
                                    display: block;
                                    text-align: center;
                                    line-height: 2.4;
                                    font-weight: 600;
                                }

                                span {
                                    display: inline-block;
                                    margin: 0 auto;
                                }
                            }

                            .list-selector-colum-2 {
                                flex-basis: 70%;

                                h6 {
                                    font-weight: 600;
                                    font-size: 16px;
                                    color: #42526e;
                                    margin-bottom: 5px;

                                    span {
                                        font-size: 12px;
                                        float: right;
                                        color: #717171;
                                    }
                                }

                                p {
                                    font-size: 14px;
                                    color: #717171;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;

                                }
                            }
                        }
                    }
                }

                li:hover a {
                    background-color: #ddd;
                }
            }
        }

        .advance-listing-colum-2 {
            flex-basis: 70%;
            border-right: 1px solid #ddd;
            position: relative;

            .advance-listing-colum-2-top {
                display: flex;
                flex-direction: row;
                padding: 10px;
                background-color: #fff;
                border-bottom: 1px solid #ddd;

                .advance-listing-colum-2-top-1 {
                    span {
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        background-color: rgb(4, 83, 43);
                        color: #fff;
                        display: block;
                        text-align: center;
                        line-height: 2.4;
                        font-weight: 600;
                    }
                }

                .advance-listing-colum-2-top-2 {
                    padding-left: 15px;

                    h6 {
                        font-weight: 600;
                        font-size: 18px;
                        color: #42526e;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 15px;
                        color: #717171;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;

                    }
                }
            }

            .advance-listing-colum-2-body {
                padding: 20px;

                table {
                    thead {
                        tr {
                            th {
                                color: #fff !important;
                            }
                        }
                    }
                }

                .date-display-type-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 30px;

                    .date-display-type-colum-1 {
                        text-align: center;
                        border: 1px solid #ddd;
                        flex-basis: 20%;
                        margin-right: 20px;
                        border-radius: 6px;

                        .month-type {
                            background-color: #ddd;
                            font-weight: 600;
                            font-size: 16px;
                            padding: 5px 0px;
                            border-top-left-radius: 6px;
                            border-top-right-radius: 6px;
                        }

                        .days-type {
                            font-size: 30px;
                            padding: 3px;
                            font-weight: normal;
                        }

                        p {
                            margin-bottom: 10px;
                        }
                    }

                    .date-display-type-colum-2 {
                        flex-basis: 80%;
                    }
                }

                .advance-listing-body-comment-row {
                    display: flex;
                    flex-direction: row;
                    margin: 40px 0px 0px 0px;

                    .advance-listing-boly-comment-short-name {
                        flex-basis: 5%;

                        span {
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            background-color: rgb(4, 83, 43);
                            color: #fff;
                            display: block;
                            text-align: center;
                            line-height: 2.4;
                            font-weight: 600;
                        }
                    }

                    .advance-listing-boly-comment-details {
                        flex-basis: 90%;
                        padding-left: 15px;

                        h6 {
                            font-weight: 600;
                            font-size: 16px;
                            color: #42526e;
                            margin-bottom: 5px;
                        }

                        p {
                            font-size: 14px;
                            color: #717171;

                        }
                    }
                }
            }

            .bottom-comment-section-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-top: 1px solid #717171;
                background-color: #fff;

                .bottom-comment-section-form {
                    flex-basis: 60%;

                    fieldset {
                        border: unset !important;
                        border-radius: unset !important;
                    }
                }

                .bottom-comment-section-form-buttons {
                    flex-basis: 40%;
                    padding: 10px;
                    background-color: #ddd;

                    .btn-message {
                        background-color: #fff;
                        color: #42526e;
                        padding: 8px 15px;
                        border-radius: 4px;
                        border: 1px solid #717171;
                        font-size: 16px;

                        svg {
                            font-size: 16px;
                        }
                    }

                    .btn-approve {
                        background-color: green;
                        color: #fff;
                        padding: 8px 15px;
                        border-radius: 4px;
                        border: 1px solid green;
                        margin-left: 10px;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .btn-reject {
                        background-color: rgb(151, 15, 15);
                        color: #fff;
                        padding: 8px 15px;
                        border-radius: 4px;
                        border: 1px solid rgb(151, 15, 15);
                        margin-left: 10px;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}