.task {
    display: flex;
    width: 100%;

    .taskContainer {
        flex: 6;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 0px;

            h3 {
                font-size: 20px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 8px 15px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
                text-transform: capitalize;
                margin-left: 10px;

                svg {
                    font-size: 16px;
                }
            }

            div {
                .MuiFormControl-root {
                    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
                        padding: 10px 20px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }


            }
            .MuiTypography-root{
                font-size: 14px !important;
            }
        }

        .view-details-row {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 20px;

            .detailItem {
                flex-basis: 32%;
                margin-bottom: 20px;

                .itemKey {
                    width: 30%;
                    display: inline-block;
                }

                .itemKey2 {
                    font-weight: 600;
                }
            }
        }

        .top {
            padding: 20px;
            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;


                .itemImg {
                    width: 100%;
                    height: unset;
                    border-radius: 8px;
                    object-fit: cover;
                }


            }
        }



        form {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 30%;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select,
                    .basic-single {
                        min-width: 100%;
                        // padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }


            }

            .btn-submit {
                width: 150px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
        }
        .ss-detail-row{
            display: inherit !important;
        }
        .edit-details-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .edit-details-col-left {
                flex-basis: 100%;
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;

                .attchment-all-top-list {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .attchment-name-image {
                        flex-basis: 30%;
                        margin-right: 1%;
                        display: flex;
                        justify-content: space-between;
                        background: #ddd;
                        padding: 10px;
                        color: #000;
                        border-radius: 4px;
                        align-items: center;
                        margin-bottom: 15px;

                        .attchment-file-name {
                            flex-basis: 80%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }

                        .action-attechment-btn {
                            flex-basis: 20%;
                            text-align: right;

                            .download-icon {
                                color: #0052cc;
                                padding: 2px;
                                background: #fff;
                                font-size: 23px;
                                border-radius: 4px;
                                margin-right: 5px;
                            }

                            .delete-icon {
                                color: #ac0b0b;
                                padding: 2px;
                                background: #fff;
                                font-size: 23px;
                                border-radius: 4px;
                            }

                            svg {
                                cursor: pointer;
                            }
                        }
                    }
                }

                h4 {
                    font-size: 20px;
                    color: #42526e;
                    margin-bottom: 15px;
                }

                .top-title-buttons {
                    list-style-type: none;
                    padding: 0;
                    margin-bottom: 20px;

                    li {
                        display: inline-block;

                        a {
                            background-color: #e4dede;
                            color: #000;
                            padding: 5px 10px;
                            margin-right: 10px;
                            text-decoration: none;
                            border-radius: 5px;
                            font-size: 16px;
                            display: flex;
                            align-items: center;

                            svg {
                                font-size: 16px;
                                margin-right: 10px;
                            }
                        }
                    }
                }

                .left-description {
                    .epik-tabs {
                        .custom-loader-tabs {
                            position: relative;

                            div {
                                div {
                                    svg {
                                        height: 50px !important;
                                    }
                                }
                            }
                        }

                        .custom-loader-tabs2 {
                            position: relative;

                            div {
                                div {
                                    svg {
                                        height: 30px !important;
                                    }
                                }
                            }
                        }

                        .file-dropzone-tabs {
                            section {
                                div {
                                    width: 260px;
                                    padding: 60px;
                                    border: 1px dashed;
                                    text-align: center;
                                    margin: 50px auto;
                                }
                            }

                        }

                        .related-stories-head {
                            margin-top: 15px;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 0px;
                            line-height: 1.33333;
                            color: #6B778C;
                        }

                        .related-stories-row {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            background-color: #FFFFFF;
                            border-radius: 3px;
                            position: relative;
                            height: 60px;
                            padding: 6px 6px;
                            margin: 15px 0px 0px 0px;
                            border: 1px solid #EBECF0;
                            cursor: pointer;

                            .related-storys-colum1 {
                                flex-basis: 30%;

                                a {
                                    color: #0052cc;
                                    text-decoration: none;
                                }

                                a:hover {
                                    text-decoration: underline;
                                }
                            }

                            .related-storys-colum2 {
                                flex-basis: 40%;

                                p {
                                    a {
                                        color: #505F79;
                                        text-decoration: none;
                                    }
                                }
                            }

                            .related-storys-colum3 {
                                flex-basis: 30%;
                                text-align: right;

                                ul {
                                    list-style-type: unset;
                                    padding: unset;
                                    text-align: right;

                                    li {
                                        display: inline-block;

                                        a {
                                            text-decoration: none;

                                            span {
                                                display: inline-block;
                                                height: 30px;
                                                width: 30px;
                                                font-size: 14px;
                                                background-color: #0052cc;
                                                color: #fff;
                                                border-radius: 50%;
                                                font-weight: 600;
                                                line-height: 2;
                                                text-align: center;
                                                margin-right: 10px;
                                            }
                                        }

                                        .select-status-ticket {
                                            background-color: #e8edf4;
                                            border: 1px solid #0052cc;
                                            color: #0052cc;
                                            border-radius: 4px;
                                            font-size: 14px;
                                            padding: 2px 6px;
                                            width: 130px;
                                        }
                                    }

                                    li:last-child {
                                        margin-top: 5px;
                                        margin-left: 5px;
                                    }
                                }
                            }

                            .related-storys-colum6 {
                                flex-basis: 20%;
                                text-align: right;

                                .match-color {
                                    color: #077c34;
                                    text-decoration: none;
                                    text-align: right;
                                }
                            }

                            .related-storys-colum5 {
                                flex-basis: 40%;

                                p {
                                    a {
                                        color: #505F79;
                                        text-decoration: none;
                                    }
                                }
                            }

                            .related-storys-colum4 {
                                flex-basis: 40%;

                                ul {
                                    list-style-type: unset;
                                    padding: unset;


                                    li {
                                        display: inline-block;

                                        a {
                                            text-decoration: none;
                                            color: #505F79;

                                            span {
                                                display: inline-block;
                                                height: 30px;
                                                width: 30px;
                                                font-size: 14px;
                                                background-color: #0052cc;
                                                color: #fff;
                                                border-radius: 50%;
                                                font-weight: 600;
                                                line-height: 2;
                                                text-align: center;
                                                margin-right: 5px;
                                            }
                                        }

                                        .select-status-ticket {
                                            background-color: #e8edf4;
                                            border: 1px solid #0052cc;
                                            color: #0052cc;
                                            border-radius: 4px;
                                            font-size: 14px;
                                            padding: 2px 6px;
                                        }
                                    }
                                }
                            }
                        }

                        .related-stories-row:hover {
                            background-color: #EBECF0;

                            .related-storys-colum2 {
                                p {
                                    a {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                        .css-1ujnqem-MuiTabs-root {
                            min-height: unset;
                        }

                        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
                            padding: 7px 10px 5px 10px;
                            border: unset !important;
                            text-transform: capitalize;
                            min-height: unset;
                            border-radius: 3px;
                            color: #42526E !important;
                            background-color: #e9ebed;
                            margin-right: 10px;
                        }

                        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
                            color: #F4F5F7 !important;
                            background-color: #505F79;
                        }

                        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover {
                            background-color: #ddd;
                        }

                        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root:focus {
                            color: #F4F5F7 !important;
                            background-color: #505F79;
                        }

                        .css-1aquho2-MuiTabs-indicator {
                            display: none !important;
                        }
                    }

                    h6 {
                        font-size: 18px;
                        color: #42526e;
                        margin-bottom: 15px;
                    }

                    .save-btn {
                        text-decoration: none;
                        color: #ffffff;
                        font-size: 16px;
                        background-color: #0052cc;
                        font-weight: 400;
                        border: 1px solid #0052cc;
                        padding: 5px 20px;
                        border-radius: 4px;
                        cursor: pointer;
                        font-weight: bold;
                        text-transform: capitalize;
                        margin-right: 10px;
                        margin: 15px 10px 15px 0px;

                    }

                    .cancel-btn {
                        text-decoration: none;
                        background-color: #e4dede;
                        color: #000;
                        font-size: 16px;
                        font-weight: 400;
                        border: 1px solid #e4dede;
                        padding: 5px 20px;
                        border-radius: 4px;
                        cursor: pointer;
                        font-weight: bold;
                        text-transform: capitalize;
                        margin: 15px 0px;
                    }

                    .bottom-comments-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-top: 20px;

                        .bottom-commrnt-col-left {
                            flex-basis: 8%;

                            span {
                                height: 40px;
                                width: 40px;
                                background-color: #0052cc;
                                color: #fff;
                                border-radius: 50%;
                                font-size: 20px;
                                display: block;
                                text-align: center;
                                line-height: 2;
                            }
                        }

                        .bottom-commrnt-col-right {
                            flex-basis: 90%;
                            word-break: break-all;

                            h6 {
                                color: #42526e;
                                font-weight: 500;
                                font-size: 16px;
                                margin-bottom: 5px;

                                span {
                                    font-weight: 400;
                                    margin-left: 10px;
                                    font-size: 12px;
                                }
                            }

                            .row-assignnee-history {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin-top: 10px;

                                .col-assignee-history-1 {
                                    h5 {
                                        color: #42526e;
                                        font-weight: normal;
                                        font-size: 16px;
                                        margin-bottom: 5px;

                                        span {
                                            height: 30px;
                                            width: 30px;
                                            border-radius: 50%;
                                            background-color: #0052cc;
                                            color: #fff;
                                            font-weight: 600;
                                            display: inline-block;
                                            text-align: center;
                                            font-size: 14px;
                                            line-height: 2;
                                        }
                                    }

                                    .assignee {
                                        background-color: #ddd;
                                        color: #000;
                                        padding: 1px 5px;
                                        border-radius: 4px;
                                    }

                                    .assign {
                                        background-color: #0052cc;
                                        color: #fff;
                                        padding: 1px 5px;
                                        border-radius: 4px;
                                    }
                                }

                                .col-assignee-history-2 {
                                    padding-left: 30px;
                                    padding-right: 30px;
                                }
                            }

                            p {
                                color: #172B4D;
                                font-size: 14px;
                            }

                            .text-area-comment {
                                min-width: 100%;
                                padding: 10px 10px;
                                border-radius: 4px;
                                border: none;
                                border: 1px solid #42526e;
                                box-sizing: border-box;
                                background-color: #fff;
                            }

                            .save-btn {
                                text-decoration: none;
                                color: #ffffff;
                                font-size: 16px;
                                background-color: #0052cc;
                                font-weight: 400;
                                border: 1px solid #0052cc;
                                padding: 5px 20px;
                                border-radius: 4px;
                                cursor: pointer;
                                font-weight: bold;
                                text-transform: capitalize;
                                margin-right: 10px;
                                margin: 15px 10px 15px 0px;
                            }

                            .cancel-btn {
                                text-decoration: none;
                                background-color: #e4dede;
                                color: #000;
                                font-size: 16px;
                                font-weight: 400;
                                border: 1px solid #e4dede;
                                padding: 5px 20px;
                                border-radius: 4px;
                                cursor: pointer;
                                font-weight: bold;
                                text-transform: capitalize;
                                margin: 15px 0px;
                            }
                        }
                    }
                }
            }

            .show-hide-left-side-colum1 {
                flex-basis: 65% !important;
            }

            .show-hide-left-side-colum2 {
                flex-basis: 20% !important;
            }

            .show-hide-right-side-colum1 {
                display: block !important;
            }

            .ss-task-summry-right{
                position: absolute;
                top: 444px;
                left: 62%;
                width: 36.5%;
                z-index: 999;
                background: #fff;
            }
            

            .edit-details-col-right {
                flex-basis: 35%;
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                display: none;

                .select-status-ticket {
                    padding: 12px 10px;
                    border-radius: 4px;
                    border: none;
                    border: 1px solid #0052cc;
                    box-sizing: border-box;
                    background-color: #0052cc;
                    color: #fff;
                    font-weight: 600;
                    width: 100%;
                }

                .right-side-details {
                    padding: 10px;
                    margin-top: 20px;
                    border: 1px solid #ddd;
                    border-radius: 10px;

                    .right-and-left-name-lable-first {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;
                    }

                    h4 {
                        font-size: 20px;
                        color: #42526e;
                        margin-bottom: 15px;
                        margin-top: 0;
                    }

                    .right-and-left-name-lable {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;

                        .css-b62m3t-container {
                            width: 300px;
                        }

                        input {
                            width: 300px;
                            padding: 10px 10px;
                            border-radius: 4px;
                            border: none;
                            border: 1px solid #42526e;
                            box-sizing: border-box;
                            background-color: #fff;
                        }

                        h6 {
                            font-size: 16px;
                            color: #42526e;

                        }

                        .custom-select-name {
                            min-width: 300px;
                            padding: 5px 15px !important;
                            border-radius: 4px;
                            border: none;
                            border: none;
                            box-sizing: border-box;
                            background-color: #fff;


                            div {
                                padding: unset;
                                display: flex;
                                align-items: center;

                            }

                            .name-shortcut {
                                padding: 3px;
                                background: #0052cc;
                                border-radius: 50%;
                                text-align: center;
                                color: #fff;
                                line-height: 2;
                                font-size: 15px;
                                margin-right: 5px;
                            }



                        }
                    }
                }
            }
        }

    }
}

.shortname-assignee {
    background: #0052cc;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    line-height: 1.5;
    font-size: 15px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    display: inline-block;
}


.name-and-short {
    display: flex;
    align-items: center;
}

.task-dropdown {
    min-width: 220px;
    margin-top: 4px;
}

.task-edit-select {
    width: 200px;
    border-radius: 4px;
    border: none;
    border: 1px solid #42526e;
    box-sizing: border-box;
    background-color: #fff;
}

.btn-submit {
    width: 150px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #0052cc;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: none;
}

.btn-section-top {
    // display: flex;

    .btn-add-new {
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        background-color: #0052cc;
        font-weight: 400;
        border: 1px solid #0052cc;
        padding: 8px 15px;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;
        text-transform: capitalize;
        margin-left: 10px;
        height: 37px;
        margin-top: 3px;

        svg {
            font-size: 16px;
        }
    }
}

.comment-single-text-box {
    margin: 0px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.assign-btn {
    background-color: #e8edf4;
    border: 1px solid #0052cc;
    color: #0052cc;
    border-radius: 4px;
    font-size: 14px;
    padding: 2px 6px;
    margin: 0px 12px;
}

.attach-file-section {
    .uploaded-files-list {
        flex-basis: 60%;

        div {
            ul {
                list-style-type: none;
                padding: 0;

                li {
                    padding: 2px 10px;
                    background-color: rgb(221 221 221 / 61%);
                    font-size: 16px;
                    text-transform: capitalize;
                    line-height: 2;
                    border-radius: 4px;
                    margin-bottom: 10px;

                    button {
                        background: transparent;
                        border: unset;
                        outline: unset;
                        float: right;
                        margin-top: 5px;

                        svg {
                            background: #000;
                            color: #fff;
                            border-radius: 50%;
                            padding: 5px;
                            font-size: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .file-attchment-icon-btn {
        flex-basis: 100%;

        span {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            line-height: 4.3;
            display: block;
            text-align: center;
            background-color: #ddd;
            color: #000;
            cursor: pointer;

            svg {
                font-size: 30px;
                color: #000;
            }
        }
    }
}

.story-epic-summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ss-top-section-btn {
    .ss-close-btn{
        background: none !important;
        border: none !important;
        padding: 8px 8px !important;
        position: absolute !important;
        top: 38%!important;
        right: 3%!important;
        svg{
            font-size: 20px;
        }
    }
}
.zoom
{
    svg{
    position:absolute !important;
    right: 4% !important;
    top:44% !important;
    text-decoration: none;
    color: #ffffff;
    background-color: #0052cc;
    border: 1px solid #0052cc;
}
}


// .ss-section-top{
//     .css-6hp17o-MuiList-root-MuiMenu-list{
//         padding: 0px !important;
//     }
//     .MuiButtonBase-root{
//         font-size: 2px !important;
//         min-width: 33px !important;
//         height: 30px !important;
//     }
//     .calling-btn-add-new{
//         height: 37px !important;
//         margin: 3px -4px 5px 6px!important;
//         color:#ffffff;
//         background-color: rgb(0, 82, 204);
//     }
//     .MuiSvgIcon-root{
//         font-size: 17px!important;
//     }
// }
// .ss-button-group{
//     text-align: end;
//     margin-top: 9px;
//     .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
//         padding: 0!important;
//     }
//     svg{
//         margin-left: 0 !important;
//         margin: -7px -3px -6px 0px !important;
//     }
// }
// .ssstory-button-group{
//     text-align: end;
//     margin-top: 5px!important;
//     svg{
//         margin-left: 0 !important;
//         margin: -7px -1px -9px 0px !important;
//     }
// }
// .style-datatable{
//     .MuiTablePagination-selectLabel{
//                     display: none !important;
//       }
//       .MuiTablePagination-toolbar{
//         padding-left: 0px !important;
//         .MuiTablePagination-selectLabel{
//             margin: 0!important;
//         }
//         .css-1zye22{
//             margin-left: 0!important;
//             span{
//                 .MuiButtonBase-root{
//                     padding: 0!important;
//                 }
//             }
//       }
//       .MuiInputBase-root{
//         margin-left: 0px !important;
//       }
    //}
    // .datagrid{
    //     .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
    //         padding-left: 0px !important;
    //         .css-16c50h-MuiInputBase-root-MuiTablePagination-select{
    //             margin: 0!important;
    //             }
    //             .css-1zye22{
    //                 margin-left: 0!important;
    //                 span{
    //                     .MuiButtonBase-root{
    //                         padding: 0!important;
    //                     }
    //                 }
    //         }
    //         .MuiTablePagination-selectLabel{
    //             display: none !important;
    //         }
    //     }
    // }
//}
// .ss-noti {
//     background-color: #0052cc;
//     color: #fff;
//     border-radius: 50%;
//     padding: 0px;
//     svg{
//         width: 22px;
//     height: 12px;
//     }
// }

// .ss-noti-newmail-icon{
//     background-color:#0052cc;
//     color: #fff;
//     border-radius: 50%;
//     padding: 0px;

//     //6px
//     .MuiSvgIcon-root{
//         width: 20px !important;
//         height: 13px !important;
//     }
//     svg {
//         width: 20px !important;
//         height: 13px !important;
//     }
// }
// .ss-top-icon .MuiButtonBase-root{
//     min-width: 37px !important;
//     margin: 2px !important;
//     .MuiSvgIcon-root{
//         height: 18px !important;
//         width: 18px !important;
//     }
// }
// .ss-arrowicon{
//     svg{
//         border: 1px solid;
//         border-radius: 50%;
//     }
// }