@import "rsuite/dist/rsuite.css";

body {
  font-family: 'Poppins', sans-serif;
}

table th {
  font-size: 16px;
  color: #42526e !important;
}

table td {
  font-size: 14px !important;
  color: #42526e;
  border-bottom: unset !important;
  text-align: left !important;
  padding: 8px 10px !important;
  vertical-align: middle !important;
}

table tbody tr:hover {
  background-color: #f4f5f7;
  cursor: pointer
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: unset !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
  font-size: 16px !important;
  color: #42526e !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-size: 14px !important;
  color: #42526e !important;
  border-bottom: unset !important;
  text-align: left !important;
  padding: 8px 10px !important;
  vertical-align: middle !important;
}

.sidebar-collapse {
  flex: 0;
}

.sidebar-collapse .top .logo img {
  margin-right: 0;
}

.sidebar-collapse .pro-item-content,
.sidebar-collapse .pro-arrow-wrapper,
.sidebar-collapse .logo-text {
  display: none;
}

.sidebar-collapse .pro-sidebar {
  width: 70px;
  min-width: 70px;
}

.sidebar-collapse:hover .pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}

.sidebar-collapse:hover .pro-item-content,
.sidebar-collapse:hover .pro-arrow-wrapper,
.sidebar-collapse:hover .logo-text {
  display: block;
}

.sidebar-collapse:hover .top .logo img {
  margin-right: 10px;
}

/* .sidebar-collapse .pro-sidebar .pro-menu .pro-menu-item .pro-inner-item:hover .pro-item-content{
  display: block;
  position: absolute;
  left: 100%;
  z-index: 999;
  background-color: #fff;
  color: #42526e;
} */

/* .sidebar-collapse .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
  overflow-y: unset;
overflow-x: unset;
} */
.master-table-body tr td:first-child {
  font-weight: 600;
  text-align: left !important;
}


.master-table-body tr td {
  font-weight: 600;
  text-align: center !important;
}

.css-1sjjn1c-MuiDataGrid-panelContent {
  overflow: inherit !important;
}

.css-emku8u {
  overflow: inherit !important;
}

.css-1e2bxag-MuiDataGrid-root {
  height: 700px !important;
}

.css-iclwpf {
  height: 700px !important;
}

.master-table-body tr td:first-child {
  font-weight: 600;
  text-align: left !important;
}


.master-table-body tr td {
  font-weight: 600;
  text-align: center !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #0052cc61 !important;
}

.css-iclwpf .MuiDataGrid-row.Mui-selected {
  background-color: #0052cc61 !important;
}

.ellips-css-data {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: block;
}

.searchWrapper {
  border: unset !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.pro-sidebar::-webkit-scrollbar {
  width: .4em;
  display: none;
}

.pro-sidebar:hover::-webkit-scrollbar {
  display: block;
}

.pro-sidebar::-webkit-scrollbar,
.pro-sidebar::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}

.pro-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .2);
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.custom-meeting-table .MuiDataGrid-row {
  min-height: 85px !important;
}

.custom-meeting-table .MuiDataGrid-row .MuiDataGrid-cell {
  min-height: 85px !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  height: 39.5px !important;
}

.rs-picker-date-menu {
  z-index: 9999 !important;
}

.MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root {
  margin-left: 4px !important;
}