.btn-with-loader {
    min-width: 150px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #0052cc;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    text-decoration: none;
}